import React, { useContext, useEffect, useState } from "react";
import AssetHeroSection, { AssetHeroSectionProps } from "@src/components/partials/dojo-inspo/AssetHero";
import AssetShareSection, { AssetShareSectionProps } from "@src/components/partials/dojo-inspo/AssetShare";
import GotInspo, { GotInspoProps } from "@src/components/partials/dojo-inspo/GotInspo";
import SEO from "@src/components/SEO";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { logEvent } from "@src/utils/logClient";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { getRelativePath } from "@src/utils/routes";
import getLocale from "@src/utils/getLocale";
import { ExternalSwitches } from "@src/utils/experiments/constants";
import { useStartExperimentWhenInAudience } from "@src/utils/experiment";
import { useStartExperiment } from "@src/utils/experiment";
import { useFeatureFlag } from "@src/utils/useFeatureFlag";
import { AppDataContext } from "@src/components/AppDataContext";

interface DojoInspoActivityProps {
  pageContext: {
    assetHeroSectionProps: AssetHeroSectionProps;
    assetShareSectionProps: AssetShareSectionProps;
    gotInspoProps: GotInspoProps;
    [key: string]: any;
  };
}
const DojoInspoActivity: React.FC<DojoInspoActivityProps> = (props) => {
  const userLanguage = getLocale(props.pageContext);
  const { translate: t } = useContext(TranslationContext);
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const appData = useContext(AppDataContext);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    if (!appData.data.loadingSession && appData.data.type === undefined) {
      setIsLoggedOut(true);
    }
  }, [appData]);

  const handleTagClick = (slug: string, label: string) => {
    logEvent({
      eventName: "web.external.activity_corner.activity_tag_button.touch",
      eventValue: label,
    });
    urlSearchParams.set("tag", slug);
    navigate(getRelativePath(`/activity-corner?${urlSearchParams.toString()}`));
  };

  useEffect(() => {
    logEvent({
      eventName: `web.external.activity_corner.load_activity`,
      eventValue: props.pageContext.assetHeroSectionProps.slug,
      metadata: {
        params,
        language: userLanguage,
      },
    });
  }, []);

  return (
    <>
      <SEO
        title={t(props.pageContext.assetHeroSectionProps.title).toString()}
        description={t(props.pageContext.assetHeroSectionProps.description).toString()}
        image={`https://static.classdojo.com/uploads/${props.pageContext.assetHeroSectionProps.previewImage.filename_disk}`}
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: t(props.pageContext.assetHeroSectionProps.title).toString(),
          description: t(props.pageContext.assetHeroSectionProps.description).toString(),
        }}
      />
      <AssetHeroSection {...props.pageContext.assetHeroSectionProps} handleTagClick={handleTagClick} />
      <AssetShareSection {...props.pageContext.assetShareSectionProps} />
      <GotInspo {...props.pageContext.gotInspoProps} />
    </>
  );
};

export default DojoInspoActivity;
